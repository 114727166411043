




































import { OrdemServico } from '@/core/models/residuo';
import { PageBase } from '@/core/models/shared';
import { OrdemServicoService } from '@/core/services/residuo';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';


@Component
export default class RelatorioOS extends PageBase { 

    service = new OrdemServicoService();
    item: OrdemServico = new OrdemServico();
    overlay: boolean = false;

    mounted() {
        const filter = this.$route.query;
        this.overlay = true;
        this.service.ListarComFiltro(0,-1,[],[],null,[],filter, "Contrato.Cliente.Bases, Contrato.Cliente.Embarcacoes, Porto, Situacao, Transportadora")
            .then(
                res=>{
                    this.item = res.data.items;
                },
                err=>{
                    if (!err.response) {
                        this.$swal("Aviso", "Não foi possível acessar a API", "error");
                    } 
                    else {
                        this.$swal("Aviso", err.response.data, "error");
                    }
                }
            ).finally(()=>{
                this.overlay = false;
            });
    }
}
